import styled from "styled-components";

const Label = styled.label`
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 18px;
  color: ${props => props.color || "#5f5e70"};
  &:first-letter {
    text-transform: uppercase;
  }
  &.hidden-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export default Label;
