import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import InputMask from "react-input-mask";

import Label from "Components/fields/Label";
import Error from "Components/Error";
import RequiredTag from "Components/fields/RequiredTag";

const InputFieldLayout = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  max-width: 100%;
  width: 264px;
  margin-bottom: 16px;
  position: relative;
  &.field-error {
    label {
      color: #ec4646;
    }
    .required-notice {
      background: #ec4646;
    }
    input {
      border: 1px solid #ec4646;
      margin: -1px;
    }
  }
  .error-alert {
    border: none;
    padding: 4px 0 0;
    .icon {
      display: none;
    }
    .message {
      margin-left: 0;
      font-size: 13px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .input-required {
    border: 1px solid ${props => props.theme.error};
  }
  .required-message {
    display: inline-block;
    color: #ec4646;
    font-size: 13px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &.is-disabled {
    label {
      opacity: 0.5;
    }
    input {
      cursor: not-allowed;
    }
  }
  input::placeholder {
    color: #38485e;
    opacity: 0.5;
  }
`;

const InputLayout = styled.div`
  position: relative;
  input {
    border: ${props =>
      props.theme.name === "contrast" ? "1px solid #98a0ab" : "none"};
    border-radius: 2px;
    background: #f5f7fa;
    padding: 0 16px;
    line-height: 40px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    &:focus {
      border: 1px solid ${props => props.theme.buttonHover};
      box-shadow: 0 0 2px ${props => props.theme.buttonHover};
      padding: 0 15px;
      outline: none;
    }
    &.changed {
      border: 1px solid #98a0ab;
      padding: 0 15px;
      box-shadow: none;
      background: #fff;
    }
    &.no-label {
      margin-top: 26px;
    }
  }
  .field-icon {
    position: absolute;
    left: ${props =>
      !props.iconAlign || props.iconAlign === "left" ? "8px" : "auto"};
    right: ${props => (props.iconAlign === "right" ? "8px" : "auto")};
    top: 38px;
    width: 22px;
    height: 16px;
    svg {
      max-width: 100%;
    }
  }
  &.with-icon input {
    padding-left: 46px;
  }
`;

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.isEmpty = this.isEmpty.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.state = {
      emptyRequired: false,
      isChanged: false
    };
  }

  isEmpty(required, value) {
    required && !value && value !== 0
      ? this.setState({
          emptyRequired: true,
          isChanged: false
        })
      : this.setState({
          emptyRequired: false,
          isChanged: false
        });
  }

  changeValue() {
    this.setState({
      isChanged: true
    });
  }

  render() {
    const {
      className,
      onChange,
      error,
      placeholder,
      value = "",
      label,
      id,
      mask = "",
      maskChar = " ",
      ariaLabel,
      required = true,
      type,
      isDisabled = false,
      hiddenLabel,
      icon,
      iconAlign
    } = this.props;

    return (
      <InputFieldLayout
        className={`field input-field${
          error || this.state.emptyRequired ? " field-error" : ""
        }${isDisabled ? " is-disabled" : ""}${
          className ? " " + className + " field-" + className : ""
        }`}
      >
        <InputLayout
          className={`
            ${icon ? "with-icon" : ""}${iconAlign ? " icon-" + iconAlign : ""}`}
        >
          {label && (
            <Label
              className={`label${hiddenLabel ? " hidden-label" : ""}`}
              htmlFor={id}
            >
              {label}
              {required ? <RequiredTag /> : ""}
            </Label>
          )}
          {icon && <span className="field-icon">{icon}</span>}
          {mask ? (
            <InputMask
              id={id}
              type={type ? type : "text"}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onClick={this.changeValue}
              mask={mask}
              maskChar={maskChar}
              aria-label={ariaLabel}
              required={required}
              aria-required={required}
              className={`${this.state.emptyRequired ? " input-required" : ""}${
                this.state.isChanged ? " changed" : ""
              }`}
              onBlur={() => this.isEmpty(required, value)}
              disabled={isDisabled}
            />
          ) : (
            <input
              id={id}
              type={type ? type : "text"}
              placeholder={!this.state.isChanged ? placeholder || label : ""}
              value={value}
              onChange={onChange}
              onClick={this.changeValue}
              name={id}
              aria-label={ariaLabel}
              required={required}
              aria-required={required}
              className={`${this.state.emptyRequired ? " input-required" : ""}${
                this.state.isChanged ? " changed" : ""
              }`}
              onBlur={() => this.isEmpty(required, value)}
              disabled={isDisabled}
            />
          )}
          {this.state.emptyRequired && (
            <div className="required-message">{label} is required.</div>
          )}
        </InputLayout>
        {error && <Error>{error}</Error>}
      </InputFieldLayout>
    );
  }
}

InputField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  useMask: PropTypes.bool,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  ariaLabel: PropTypes.string,
  required: PropTypes.bool,
  intl: PropTypes.object,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  icon: PropTypes.node,
  iconAlign: PropTypes.string
};

export default injectIntl(InputField);
