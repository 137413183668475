import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import Button from "./Button";
import InputField from "./fields/InputField";
import TextAreaField from "./fields/TextAreaField";
import ToggleSwitch from "Components/ToggleSwitch";
import Loading from "Components/Loading";
import ButtonWrapper from "Components/ButtonWrapper";
import Error from "Components/Error";

const VariableFormLayout = styled.form`
  padding: 32px;
  min-height: 200px;
  width: 100%;
  .primary {
    margin-right: 10px;
  }
  textarea {
    width: 100%;
    box-sizing: border-box;
  }
  .toggle-field {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .toggle-field + .toggle-field {
    border-top: 1px solid #c9d0e4;
    padding-top: 24px;
  }
  .toggle-field + .actions {
    padding-top: 16px;
  }
  .row {
    > div {
      width: 100%;
      box-sizing: boarder-box;
    }
    > div:first-child {
      width: 33%;
      padding-right: 20px;
    }
  }
`;

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  &.add-form {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(152, 160, 171, 0.4);
    margin-bottom: 16px;
  }
`;

class VariableForm extends React.Component {
  constructor(props) {
    super(props);
    this.onNameChange = this.onNameChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onIsJsonChange = this.onIsJsonChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onOverride = this.onOverride.bind(this);
    // @todo: refactor the following so the we don't have to manually check each value.
    this.state = {
      id: this.props.variable.id ? this.props.variable.id : "",
      name: this.props.variable.name ? this.props.variable.name : "",
      value: this.props.variable.value ? this.props.variable.value : "",
      is_json:
        typeof this.props.variable.is_json !== "undefined"
          ? this.props.variable.is_json
          : false,
      is_inheritable:
        typeof this.props.variable.is_inheritable !== "undefined"
          ? this.props.variable.is_inheritable
          : true,
      is_inherited:
        typeof this.props.variable.inherited !== "undefined"
          ? this.props.variable.inherited
          : false,
      is_sensitive:
        typeof this.props.variable.is_sensitive !== "undefined"
          ? this.props.variable.is_sensitive
          : false,
      is_enabled:
        typeof this.props.variable.is_enabled !== "undefined"
          ? this.props.variable.is_enabled
          : true,
      visible_build:
        typeof this.props.variable.visible_build !== "undefined"
          ? this.props.variable.visible_build
          : true,
      visible_runtime:
        typeof this.props.variable.visible_runtime !== "undefined"
          ? this.props.variable.visible_runtime
          : true,
      isChanged: this.props.isChanged,
      overridden: false
    };
    this.originalState = this.state;
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
      isChanged: true
    });
    this.props.isChangedUpdate();
  }

  onNameChange(event) {
    this.onChange("name", event.target.value);
  }

  onValueChange(event) {
    this.onChange("value", event.target.value);
  }

  onIsJsonChange() {
    this.onChange("is_json", !this.state.is_json);
  }

  onOverride() {
    this.setState({
      overridden: true
    });
  }

  onSave() {
    if (this.props.environmentForm) {
      this.props.onSave({
        id: this.state.id ? this.state.id : null,
        name: this.state.name,
        value: this.state.value,
        is_json: this.state.is_json,
        is_sensitive: this.state.is_sensitive,
        inherited: this.state.inherited,
        is_enabled: this.state.is_enabled,
        is_inheritable: this.state.is_inheritable
      });
    } else {
      this.props.onSave({
        id: this.state && this.state.id ? this.state.id : null,
        name: this.state.name,
        value: this.state.value,
        is_json: this.state.is_json,
        is_sensitive: this.state.is_sensitive,
        visible_build: this.state.visible_build,
        visible_runtime: this.state.visible_runtime
      });
    }
  }

  onCancel() {
    this.setState({
      isChanged: this.props.isChanged
    });
    this.props.onCancel();
  }

  render() {
    const {
      intl,
      isLoading,
      errors,
      name,
      isNew,
      variable,
      onDelete,
      environmentForm,
      inherited
    } = this.props;

    return (
      <Layout className={isNew ? "add-form" : ""}>
        <LiveMessage
          message={
            name
              ? intl.formatMessage({ id: "edit_variable" })
              : intl.formatMessage({ id: "add_variable" })
          }
          aria-live="polite"
        />
        <VariableFormLayout
          id={
            environmentForm
              ? isNew
                ? "environment-variable-add-form"
                : "environment-variable-edit-form"
              : isNew
                ? "project-variable-add-form"
                : "project-variable-edit-form"
          }
          aria-labelledby="edit-variable-heading"
        >
          {errors &&
            errors.map((error, i) => {
              return <Error key={i}>{error}</Error>;
            })}
          <div className="row">
            <InputField
              id="variable-name-input"
              label={intl.formatMessage({ id: "name" })}
              value={this.state.name || ""}
              onChange={this.onNameChange}
              error={errors && errors.get && errors.get("name")}
              isDisabled={inherited && !this.state.overridden}
            />
            <TextAreaField
              id="variable-value-input"
              label={intl.formatMessage({ id: "value" })}
              value={this.state.value || ""}
              onChange={this.onValueChange}
              error={errors && errors.get && errors.get("value")}
              isDisabled={inherited && !this.state.overridden}
            />
          </div>
          <ToggleSwitch
            id="is-json-switch"
            title={intl.formatMessage({ id: "is_json" })}
            description={intl.formatMessage({
              id: "is_json.description"
            })}
            onClick={() => {
              this.onChange("is_json", !this.state.is_json);
            }}
            value={this.state.is_json}
            enabled={!inherited || !(inherited && !this.state.overridden)}
          />
          <ToggleSwitch
            id="sensitive-switch"
            title={intl.formatMessage({ id: "sensitive" })}
            description={intl.formatMessage({
              id: "sensitive.description"
            })}
            onClick={() => {
              this.onChange("is_sensitive", !this.state.is_sensitive);
            }}
            value={this.state.is_sensitive}
            enabled={
              (!inherited || !(inherited && !this.state.overridden)) &&
              !this.originalState.is_sensitive
            }
          />
          {environmentForm && (
            <React.Fragment>
              <ToggleSwitch
                id="enabled-switch"
                title={intl.formatMessage({ id: "enabled" })}
                description={intl.formatMessage({
                  id: "enabled.description"
                })}
                onClick={() => {
                  this.onChange("is_enabled", !this.state.is_enabled);
                }}
                value={this.state.is_enabled}
                enabled={!inherited || !(inherited && !this.state.overridden)}
              />
              <ToggleSwitch
                id="inheritable-switch"
                title={intl.formatMessage({ id: "inheritable" })}
                description={intl.formatMessage({
                  id: "inheritable.description"
                })}
                onClick={() => {
                  this.onChange("is_inheritable", !this.state.is_inheritable);
                }}
                value={this.state.is_inheritable}
                enabled={!inherited || !(inherited && !this.state.overridden)}
              />
            </React.Fragment>
          )}
          {!environmentForm && (
            <React.Fragment>
              <ToggleSwitch
                id="build-switch"
                title={intl.formatMessage({ id: "during_build" })}
                description={intl.formatMessage({
                  id: "during_build.description"
                })}
                onClick={() => {
                  this.onChange("visible_build", !this.state.visible_build);
                }}
                value={this.state.visible_build}
              />
              <ToggleSwitch
                id="run-switch"
                title={intl.formatMessage({ id: "during_run" })}
                description={intl.formatMessage({
                  id: "during_run.description"
                })}
                onClick={() => {
                  this.onChange("visible_runtime", !this.state.visible_runtime);
                }}
                value={this.state.visible_runtime}
              />
            </React.Fragment>
          )}
          {isLoading ? (
            <Loading />
          ) : (
            (this.state.isChanged ||
              isNew ||
              this.state.is_inherited ||
              this.state.overridden ||
              (variable.hasPermission &&
                variable.hasPermission("#delete"))) && (
              <ButtonWrapper className="actions">
                {(this.state.isChanged || this.state.overridden || isNew) && (
                  <React.Fragment>
                    <Button
                      id="save-variable-btn"
                      type="submit"
                      aria-label={intl.formatMessage({
                        id: isNew ? "add_variable" : "save"
                      })}
                      onClick={
                        this.state.name && this.state.value && this.onSave
                      }
                    >
                      {intl.formatMessage({
                        id: isNew ? "add_variable" : "save"
                      })}
                    </Button>
                    <Button
                      id="cancel-variable-btn"
                      type="button"
                      className="secondary"
                      aria-label={intl.formatMessage({ id: "cancel" })}
                      onClick={this.onCancel}
                    >
                      {intl.formatMessage({ id: "cancel" })}
                    </Button>
                  </React.Fragment>
                )}
                {this.state.is_inherited &&
                  this.state.is_enabled &&
                  !this.state.overridden && (
                    <Button
                      id={`variable-list-${variable.name}-override-btn`}
                      type="button"
                      arial-label="Override"
                      onClick={this.onOverride}
                    >
                      Override
                    </Button>
                  )}

                {variable.hasPermission &&
                  variable.hasPermission("#delete") && (
                    <Button
                      className="outline"
                      id={`variable-list-${variable.name}-delete-btn`}
                      type="button"
                      aria-label={intl.formatMessage({ id: "delete" })}
                      onClick={onDelete}
                    >
                      {intl.formatMessage({ id: "delete" })}
                    </Button>
                  )}
              </ButtonWrapper>
            )
          )}
        </VariableFormLayout>
      </Layout>
    );
  }
}

VariableForm.propTypes = {
  variable: PropTypes.object,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  errors: PropTypes.object,
  intl: PropTypes.object,
  environmentForm: PropTypes.bool,
  name: PropTypes.string,
  isNew: PropTypes.bool,
  inherited: PropTypes.bool,
  isChangedUpdate: PropTypes.func,
  isChanged: PropTypes.bool
};

export default injectIntl(VariableForm);
