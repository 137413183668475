import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Label from "Components/fields/Label";
import Error from "Components/Error";

const InputLayout = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  /* Base for label styling */
  [type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    height: 18px;
    width: 18px;
    opacity: 0;
  }
  label {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    line-height: 24px;
    color: #4a495e;
    font-weight: 400;
    font-size: 15px;
  }
  /* checkbox aspect */
  label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid #98a0ab;
    background: #fff;
    border-radius: 3px;
    &:hover {
      border-color: ${props => props.theme.buttonBg};
    }
  }
  /* checked mark aspect */
  &.checked label:after {
    content: "";
    position: absolute;
    top: 7px;
    left: 4px;
    transition: all 0.2s;
    background: ${props => props.theme.buttonBg};
    background-size: contain;
    height: 10px;
    width: 10px;
    border-radius: 1px;
  }
  /* checked mark aspect changes */
  label:after {
    opacity: 0;
  }
  &.checked label:after {
    opacity: 1;
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #c9d0e4;
  }
  [type="checkbox"]:disabled + label {
    cursor: not-allowed;
    opacity: 0.5;
  }
  /* accessibility */
  [type="checkbox"]:checked + label:before {
    border-color: ${props => props.theme.buttonBg};
  }
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    border-color: ${props => props.theme.buttonHover};
    box-shadow: 0 0 2px ${props => props.theme.buttonHover};
  }
  input.changed:checked:focus + label:before,
  input.changed:not(:checked):focus + label:before {
    box-shadow: none;
    border-color: ${props => props.theme.buttonBg};
  }

  /* hover style just for information */
  label:hover:before {
    border-color: ${props => props.theme.buttonBg};
  }
  .description {
    font-size: 15px;
    padding-left: 40px;
    color: #4a495e;
  }
  .error-alert {
    border: none;
    padding: 4px 0 0;
    .icon {
      display: none;
    }
    .message {
      margin-left: 0;
      font-size: 13px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

class CheckboxField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChanged: false
    };
    this.valueChanged = this.valueChanged.bind(this);
    this.valueReset = this.valueReset.bind(this);
  }
  valueChanged() {
    this.setState({
      isChanged: true
    });
  }
  valueReset() {
    this.setState({
      isChanged: false
    });
  }
  render() {
    const {
      onChange,
      error,
      placeholder,
      value = false,
      label,
      forId,
      description,
      id,
      required = false,
      disabled = false
    } = this.props;

    return (
      <InputLayout
        className={`field checkbox-field${value ? " checked" : ""}${
          disabled ? " disabled" : ""
        }`}
      >
        <div>
          <input
            className={`checkbox${value ? " checked" : ""}${
              this.state.isChanged ? " changed" : ""
            }`}
            id={forId}
            type="checkbox"
            name={forId || id}
            placeholder={placeholder}
            checked={value}
            onChange={onChange}
            onClick={this.valueChanged}
            onBlur={this.valueReset}
            aria-checked={value}
            required={required}
            disabled={disabled}
          />
          <Label id={id ? id : forId + "-label"} htmlFor={forId}>
            {label}
          </Label>
          {description && <div className="description">{description}</div>}
        </div>
        {error && <Error>{error}</Error>}
      </InputLayout>
    );
  }
}

CheckboxField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  forId: PropTypes.string.isRequired,
  description: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};

export default CheckboxField;
