import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import styled from "styled-components";

import SettingLine from "Components/SettingLine";
import VariableForm from "Components/VariableForm";
import Heading2 from "Components/styleguide/Heading2";
import EmptyText from "Components/EmptyText";
import AddLink from "Components/AddLink";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import InfoDialog from "Components/InfoDialog";
import logger from "Libs/logger";
import PageDescription from "Components/PageDescription";

const SettingLineWrapper = styled.div`
  .setting-line {
    .action {
      width: 60px;
    }
  }
  .variable-info {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .variable-info-main {
    width: 75%;
    display: flex;
    align-items: center;
  }
  .variable-name {
    margin-right: 4px;
  }
  .variable-value {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-left: 4px;
  }
  .inherited,
  .labels {
    color: #4a495e;
    font-weight: 600;
    font-size: 13px;
  }
  .inherited {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
  .info-dialog {
    margin-right: 8px;
    margin-left: 0;
    display: inline-flex;
    .dialog > div {
      width: 300px;
    }
    div:nth-of-type(2) {
      text-align: left;
      width: auto;
    }
  }
  .disabled {
    font-weight: 600;
    margin-left: 8px;
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    display: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }
`;

class EnvironmentVariableListField extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addNewVariable = this.addNewVariable.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.expand = this.expand.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isChanged = this.isChanged.bind(this);
    this.state = {
      variables: [],
      isModalOpen: false,
      isModalLeaveOpen: false,
      isChanged: false,
      changeIndex: false,
      index: ""
    };
  }

  componentDidMount() {
    this.props.loadVariables(this.props.environment);
    this.setState(() => ({
      variables: this.sortVariableList(this.props.variables)
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.environment !== this.props.environment) {
      this.props.loadVariables(nextProps.environment);
    }
    if (nextProps.variables !== this.props.variables) {
      if (this.state.isNew !== true) {
        this.setState(() => ({
          variables: this.sortVariableList(nextProps.variables)
        }));
      }
    }
  }

  sortVariableList(variables) {
    return variables.sort((a, b) => {
      return a.id !== b.id ? (a.id < b.id ? -1 : 1) : 0;
    });
  }

  expand(index) {
    if (this.props.editedLine === index || this.props.isNew) {
      this.cancel();
    } else {
      if (this.state.isChanged && !this.state.isModalLeaveOpen) {
        this.setState({
          isModalLeaveOpen: true,
          isChanged: true,
          changeIndex: true,
          index: index
        });
      } else {
        this.setState({
          isChanged: false,
          changeIndex: false,
          index: index
        });
        this.props.editLine(index, false);
      }
    }
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.variables[index] = {
        ...(this.state.variables[index] || this.props.variables[index]),
        [field]: value
      };

      return nextState;
    });
  }

  isChanged() {
    this.setState({
      isChanged: true
    });
  }

  save(variable) {
    const variableIsRenamed =
      variable.id !== null && variable.name !== variable.id;
    if (variableIsRenamed) {
      this.deleteBeforeRename(variable.id);
    }
    this.props.updateVariable(this.props.environment, variable);
    this.setState({
      isChanged: false,
      index: "",
      isNew: false
    });
  }

  async deleteBeforeRename(id) {
    const variable = this.props.variables.get(id);
    try {
      await variable.delete();
    } catch (err) {
      logger(err);
    }
  }

  cancel() {
    if (this.props.isNew) {
      this.setState(prevState => {
        const nextState = prevState;
        nextState.isNew = false;
        nextState.variables.splice(0, 1);
        return nextState;
      });
    }
    if (this.state.isChanged && !this.state.isModalLeaveOpen) {
      this.setState({
        isModalLeaveOpen: true,
        isChanged: true
      });
    } else {
      this.props.cancel();
    }
  }

  addNewVariable() {
    this.setState(prevState => {
      const nextState = prevState;
      if (!nextState.isNew) {
        nextState.isNew = true;
        nextState.variables.unshift({});
      }
      this.props.editLine(0, true);
      return nextState;
    });
  }

  openModal(variable) {
    this.setState({
      isModalOpen: true,
      variable
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      isModalLeaveOpen: false,
      isChanged: false
    });
  }

  render() {
    const { intl, environment, isLoading, isUpdateLoading, isNew } = this.props;
    const { variables } = this.state;
    return (
      <div>
        <LiveMessage
          message={`${this.props.environment &&
            this.props.environment.title} environment-level variable settings`}
          aria-live="polite"
        />
        {environment &&
          environment.hasPermission &&
          environment.hasPermission("#manage-variables") && (
            <AddLink id="add-new-variable" onClick={this.addNewVariable} />
          )}
        <Heading2 id="settings-heading">
          {intl.formatMessage({ id: "variables" })}
        </Heading2>
        <PageDescription>
          <InfoDialog
            title="Learn more"
            linkText="Learn more"
            to="https://docs.platform.sh/configuration/app/variables.html#variables"
            text="Set variables at both project or environment levels."
          />
          <p>
            Environment variables are available in this environment and its
            children only. They are not available in the build process.
          </p>
          &bull; Variables beginning with <code>env:</code> will be exposed as
          Unix environment variables<br />
          &bull; Variables beginning with <code>php:</code> will be interpreted
          as php.ini directives.<br />
          &bull; All other variables will be part of the environment{" "}
          <code>$PLATFORM_VARIABLES</code> variable
        </PageDescription>
        <section aria-labelledby="settings-heading">
          {isLoading && <Loading />}
          {!isLoading &&
            this.state.variables.length === 0 && (
              <EmptyText>
                {intl.formatMessage({ id: "no_variables" })}
              </EmptyText>
            )}
          {variables &&
            variables.map((variable, index) => {
              let labels = [];
              !variable.is_enabled && labels.push("Disabled");
              variable.inherited && labels.push("Inherited");
              variable.is_json && labels.push("Json");
              variable.is_sensitive && labels.push("Sensitive");
              variable.is_inheritable && labels.push("Inheritable");
              return (
                <SettingLineWrapper key={`${variable.name}-${index}-read`}>
                  <SettingLine
                    id={`environment-variable-list-${variable.name}`}
                    info={
                      <div className="variable-info">
                        <span className="variable-info-main">
                          <span className="variable-name">{variable.name}</span>{" "}
                          ={" "}
                          <span className="variable-value">
                            {variable.is_sensitive ? "*****" : variable.value}
                          </span>
                        </span>
                        {labels.length !== 0 && (
                          <span className="labels">{labels.join(", ")}</span>
                        )}
                      </div>
                    }
                    isNew={!variable.id}
                    addNewTitle="Add variable"
                    isOpen={this.props.editedLine === index}
                    openText={intl.formatMessage({ id: "edit" })}
                    onClick={() => this.expand(index)}
                  >
                    {this.props.editedLine === index && (
                      <VariableForm
                        id={variable.id}
                        key={`${variable.name}-${index}-edit`}
                        onSave={this.save}
                        onCancel={this.cancel}
                        onDelete={() => this.openModal(variable)}
                        isNew={isNew}
                        errors={this.props.errors}
                        isLoading={isUpdateLoading}
                        variable={variable}
                        environmentForm={true}
                        value={variable.value}
                        name={variable.name}
                        inherited={variable.inherited}
                        isChanged={this.state.isChanged}
                        isChangedUpdate={this.isChanged}
                      />
                    )}
                  </SettingLine>
                </SettingLineWrapper>
              );
            })}
          <ModalConfirmDelete
            isOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            deleteFunction={() => this.props.delete(this.state.variable)}
            itemType="variable"
            itemName={this.state.variable && this.state.variable.name}
            itemId={this.state.variable && this.state.variable.name}
          />
          <ModalConfirmLeaveForm
            isOpen={this.state.isModalLeaveOpen}
            closeModal={this.closeModal}
            continueFunction={
              this.state.changeIndex
                ? () => {
                    this.expand(this.state.index);
                  }
                : () => {
                    this.cancel();
                  }
            }
            cancelFunction={this.closeModal}
          />
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadVariables: environment =>
    import("Reducers/environment/settings/variable").then(reducer =>
      dispatch(
        reducer.loadVariables(
          props.organizationId,
          props.projectId,
          props.environmentId,
          environment
        )
      )
    ),
  updateVariable: (environment, variable) =>
    import("Reducers/environment/settings/variable").then(reducer =>
      dispatch(
        reducer.updateVariable(
          props.organizationId,
          props.projectId,
          environment,
          variable
        )
      )
    ),
  editLine: (index, isNew) =>
    import("Reducers/environment/settings/variable").then(reducer =>
      dispatch(reducer.editLine(index, isNew))
    ),
  delete: variable =>
    import("Reducers/environment/settings/variable").then(reducer =>
      dispatch(
        reducer.deleteVariable(
          props.organizationId,
          props.projectId,
          props.environmentId,
          variable
        )
      )
    ),
  cancel: () =>
    import("Reducers/environment/settings/variable").then(reducer =>
      dispatch(reducer.cancelUpdateVariable())
    )
});

const mapStateToProps = (state, props) => {
  const variable = state.environmentVariable || new Map();
  const environment = state.environment || new Map();

  return {
    editedLine: variable.get("editedLine"),
    isNew: variable.get("isNew"),
    errors: variable.get("errors", new Map()),
    variables: variable
      .getIn(
        ["data", props.organizationId, props.projectId, props.environmentId],
        new Map()
      )
      .valueSeq()
      .toJS(),
    environment: environment.getIn([
      "data",
      props.organizationId,
      props.projectId,
      props.environmentId
    ]),
    isLoading: variable.get("loading"),
    isUpdateLoading: variable.get("updateLoading")
  };
};

EnvironmentVariableListField.propTypes = {
  loadVariables: PropTypes.func,
  onChange: PropTypes.func,
  updateVariable: PropTypes.func,
  editLine: PropTypes.func,
  delete: PropTypes.func,
  cancel: PropTypes.func,
  environment: PropTypes.object,
  variables: PropTypes.object,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isNew: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  errors: PropTypes.object,
  intl: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentVariableListField));
