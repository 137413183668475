import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import CustomScroll from "react-custom-scroll";

import Error from "Components/Error";
import Label from "Components/fields/Label";
import RequiredTag from "Components/fields/RequiredTag";

const InputFieldLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  position: relative;
  .description {
    font-size: 15px;
    color: #4a495e;
  }
  &.field-error {
    margin-bottom: 32px;
    label {
      color: #ec4646;
    }
    .required-notice {
      background: #ec4646;
    }
    .rcs-custom-scroll {
      border: 1px solid #ec4646;
      margin: -1px;
    }
  }
  .error-alert {
    border: none;
    padding: 4px 0 0;
    .icon {
      display: none;
    }
    .message {
      margin-left: 0;
      font-size: 13px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .required-message {
    display: inline-block;
    color: #ec4646;
    font-size: 13px;
    line-height: 18px;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &.is-disabled {
    label {
      opacity: 0.5;
    }
    textarea {
      cursor: not-allowed;
    }
  }
`;

const InputLayout = styled.div`
  textarea {
    border: none;
    background: transparent;
    padding: 8px 16px;
    line-height: 24px;
    box-sizing: border-box;
    font-size: 15px;
    width: 100%;
    resize: none;
    overflow: hidden;
    &:focus {
      outline: none;
    }
  }
  .rcs-custom-scroll {
    width: 400px;
    border-radius: 2px;
    background: #f5f7fa;
    .rcs-custom-scrollbar {
      width: 4px;
      right: 8px;
    }
    .rcs-inner-handle {
      background: #4a495e;
      margin-top: 8px;
    }
  }
  .rcs-inner-container {
    max-height: 240px;
    overflow: hidden;
  }
  &.focused .rcs-custom-scroll {
    border: 1px solid ${props => props.theme.buttonHover};
    box-shadow: 0 0 2px ${props => props.theme.buttonHover};
    margin: -1px;
  }
  &.changed .rcs-custom-scroll {
    border: 1px solid #98a0ab;
    box-shadow: none;
    background: #fff;
    margin: -1px;
  }
  &.no-label .rcs-custom-scroll {
    margin-top: 26px;
  }
`;

class TextAreaField extends React.Component {
  constructor(props) {
    super(props);
    this.isEmpty = this.isEmpty.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.isFocused = this.isFocused.bind(this);
    this.state = {
      emptyRequired: false,
      isChanged: false,
      isFocused: false,
      initialHeight: 88,
      currentHeight: 88
    };
  }

  isEmpty(required, value) {
    required && !value
      ? this.setState({
          emptyRequired: true,
          isChanged: false,
          isFocused: false
        })
      : this.setState({
          emptyRequired: false,
          isChanged: false,
          isFocused: false
        });
  }

  changeValue(e) {
    const currentHeight =
      e && e.target && e.target.scrollHeight > 88 ? e.target.scrollHeight : 88;
    this.setState({
      isChanged: true,
      isFocused: false,
      currentHeight: currentHeight
    });
  }

  isFocused() {
    this.setState({
      isFocused: true
    });
  }

  render() {
    const {
      onChange,
      error,
      placeholder,
      value = "",
      label,
      id,
      required = true,
      description,
      isDisabled
    } = this.props;

    return (
      <InputFieldLayout
        className={`field text-area-field${
          error || this.state.emptyRequired ? " field-error" : ""
        }${isDisabled ? " is-disabled" : ""}`}
      >
        <InputLayout
          className={`${this.state.emptyRequired ? " input-required" : ""}${
            this.state.isChanged ? " changed" : ""
          }${this.state.isFocused ? " focused" : ""}`}
        >
          {label && (
            <Label className="label" htmlFor={id}>
              {label}
              {required ? <RequiredTag /> : ""}
            </Label>
          )}
          <CustomScroll
            className={`scrollbar${this.state.isFocused ? " focused" : ""}`}
            style={{
              width: 400,
              height: this.state.initialHeight,
              margin: 0,
              padding: 15
            }}
          >
            <textarea
              id={id}
              name={id}
              placeholder={placeholder ? placeholder : `Enter ${label} here...`}
              value={value}
              style={{ height: this.state.currentHeight }}
              onChange={e => {
                onChange(e);
                this.changeValue(e);
              }}
              onClick={this.changeValue}
              required={required}
              aria-required={required}
              onFocus={this.isFocused}
              onBlur={() => this.isEmpty(required, value)}
              disabled={isDisabled}
            />
          </CustomScroll>
        </InputLayout>
        {this.state.emptyRequired && (
          <div className="required-message">{label} is required.</div>
        )}
        {description && <div className="description">{description}</div>}
        {error && <Error>{error}</Error>}
      </InputFieldLayout>
    );
  }
}

TextAreaField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  intl: PropTypes.object,
  description: PropTypes.string,
  isDisabled: PropTypes.bool
};

export default injectIntl(TextAreaField);
