import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import PlusIcon from "Icons/PlusIcon";

const AddButton = styled.button`
  background-color: ${props => props.theme.buttonBg};
  border-color: ${props => props.theme.buttonBg};
  color: ${props => props.theme.buttonText};
  padding: 0px 16px 0 16px;
  border-radius: 2px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  float: right;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${props => props.theme.buttonHover};
    border-color: ${props => props.theme.buttonHover};
  }
  &:focus {
    border-color: ${props => props.theme.buttonHover};
    box-shadow: 0 0 2px ${props => props.theme.buttonHover};
    outline: none;
  }
  &:active {
    background-color: ${props => props.theme.buttonHover};
    box-shadow: none;
  }
  span.text {
    display: inline-block;
    margin-top: -2px;
    &:first-letter {
      text-transform: capitalize;
    }
  }
  span.icon {
    width: 16px;
    display: flex;
    align-items: center;
    height: 30px;
    svg {
      width: 10px;
      height: auto;
    }
  }
`;
class AddLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl,
      text = intl.formatMessage({ id: "add" }),
      onClick,
      id
    } = this.props;

    return (
      <AddButton
        id={id}
        type="button"
        className="add-link"
        onClick={onClick}
        aria-label={text}
      >
        <span className="icon">
          <PlusIcon />
        </span>
        <span className="text">{text}</span>
      </AddButton>
    );
  }
}

AddLink.propTypes = {
  intl: PropTypes.object,
  text: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string
};

export default injectIntl(AddLink);
