import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import ModalWrapper from "Components/ModalWrapper";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";

const ModalBody = styled.div`
  margin-bottom: 48px;
  color: #38485e;
`;

class ModalConfirmDelete extends React.Component {
  constructor(props) {
    super(props);
    this.modalClassOverride = this.modalClassOverride.bind(this);
    this.overlayClassOverride = this.overlayClassOverride.bind(this);

    this.state = {
      modalCustomClass: "modal-after-open",
      overlayCustomClass: "overlay-after-open"
    };
  }

  modalClassOverride(modalClass) {
    if (modalClass) {
      this.setState({
        modalCustomClass: modalClass + " " + this.state.modalCustomClass
      });
    }
    return this.state.modalCustomClass;
  }

  overlayClassOverride(overlayClass) {
    if (overlayClass) {
      this.setState({
        overlayCustomClass: overlayClass + " " + this.state.overlayCustomClass
      });
    }
    return this.state.overlayCustomClass;
  }

  render() {
    const {
      title = "Are you sure you want to delete this?",
      closeModal,
      isOpen,
      deleteFunction,
      itemType,
      itemName,
      itemId,
      intl,
      body,
      deleteText,
      modalSize = "small",
      deleteButton = true,
      cancelButton = true,
      cancelText
    } = this.props;
    return (
      <ModalWrapper
        modalClass={`modal-${modalSize}`}
        isOpen={isOpen}
        closeModal={closeModal}
        title={title}
      >
        <ModalBody>
          {body ? (
            body
          ) : (
            <span>
              Yes, delete the {itemType} <strong>{itemName}</strong>.
            </span>
          )}
        </ModalBody>
        <ButtonWrapper className="modal-buttons">
          {deleteButton && (
            <Button
              id={`delete-${itemId}-confirm-modal-button`}
              type="submit"
              aria-label={
                deleteText ? deleteText : intl.formatMessage({ id: "delete" })
              }
              onClick={() => {
                deleteFunction();
                closeModal();
              }}
            >
              {deleteText ? deleteText : intl.formatMessage({ id: "delete" })}
            </Button>
          )}
          {cancelButton && (
            <Button
              type="button"
              className="secondary"
              aria-label={intl.formatMessage({ id: "cancel" })}
              onClick={() => closeModal()}
            >
              {cancelText ? cancelText : intl.formatMessage({ id: "cancel" })}
            </Button>
          )}
        </ButtonWrapper>
      </ModalWrapper>
    );
  }
}

ModalConfirmDelete.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteFunction: PropTypes.func,
  itemType: PropTypes.string,
  itemName: PropTypes.string,
  itemId: PropTypes.string,
  intl: PropTypes.object,
  body: PropTypes.node,
  deleteText: PropTypes.string,
  cancelText: PropTypes.string,
  modalSize: PropTypes.string,
  deleteButton: PropTypes.bool,
  cancelButton: PropTypes.bool
};

export default injectIntl(ModalConfirmDelete);
