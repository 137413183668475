import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const RequiredWrapper = styled.span`
  background-color: ${props => props.theme.brandColor};
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 4px;
  margin: 0 0 2px 4px;
`;

class RequiredTag extends React.Component {
  render() {
    const { id } = this.props;
    return (
      <RequiredWrapper
        className="required-notice"
        data-tip
        data-for={`${id}-required`}
      >
        <ReactTooltip
          id={`${id}-required`}
          place="top"
          type="dark"
          effect="solid"
        >
          Required field
        </ReactTooltip>
      </RequiredWrapper>
    );
  }
}

RequiredTag.propTypes = {
  id: PropTypes.string
};

export default RequiredTag;
