import React from "react";
import PropTypes from "prop-types";

import EnvironmentVariableListField from "Containers/EnvironmentVariableListField";
import ContentLayout from "Components/ContentLayout";

class EnvironmentSettingsVariable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { projectId, environmentId, organizationId } = this.props.params;

    return (
      <ContentLayout className="settings">
        <EnvironmentVariableListField
          projectId={projectId}
          environmentId={environmentId}
          organizationId={organizationId}
        />
      </ContentLayout>
    );
  }
}

EnvironmentSettingsVariable.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    environmentId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired
  })
};

export default EnvironmentSettingsVariable;
